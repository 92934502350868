import React, { useContext } from "react"
import styled, { createGlobalStyle } from "styled-components"
import Nav from "./nav"
import vars from "../../styles/vars"

import {
  NavigationStateContext,
  NavigationDispatchContext,
} from "../../context/navigation-context-provider"

import NavActionEnum from "../../enums/navigation-action-enum"
import MobileNav from "./mobile-nav"

const Header = props => {
  const { float } = props
  const navigationState = useContext(NavigationStateContext)
  const navigationDispatch = useContext(NavigationDispatchContext)

  return (
    <>
      <GlobalStyle
        isNavOpen={navigationState.isNavOpen}
        isMobileNavOpen={navigationState.isMobileNavOpen}
      />
      <MobileNav />
      <HeaderContainer float={float}>
        <Nav />
      </HeaderContainer>
      {navigationState.isNavOpen && (
        <StyledOverlay
          onClick={() => navigationDispatch({ type: NavActionEnum.close })}
        />
      )}
      {navigationState.isMobileNavOpen && (
        <MobileStyledOverlay
          onClick={() =>
            navigationDispatch({ type: NavActionEnum.toggleMobileNav })
          }
        />
      )}
    </>
  )
}

export default Header

const GlobalStyle = createGlobalStyle`
  @media (min-width: ${vars.screen_xl_min}) {
    ${props =>
      props.isNavOpen &&
      "html, body {margin: 0; height: 100%; overflow: hidden}"}
  }
  @media (max-width: ${vars.screen_lg_max}) {
    ${props =>
      props.isMobileNavOpen &&
      "html, body {margin: 0; height: 100%; overflow: hidden}"}
  }
`

const HeaderContainer = styled.header`
  position: ${props => (props.float ? "absolute" : "relative")};
  width: 100%;
  z-index: 100;
  height: 80px;
  background: ${props => (props.float ? vars.blackTransparent() : vars.black)};
  color: ${vars.white};
  top: 0;
  left: 0;
`

const StyledOverlay = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 20;
  background: rgba(225, 225, 225, 0.3);
  width: 100%;
  height: 100%;
  @media (max-width: ${vars.screen_lg_max}) {
    display: none;
  }
`

const MobileStyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background: rgba(225, 225, 225, 0.3);
  width: 100%;
  height: 100%;
  @media (min-width: ${vars.screen_xl_min}) {
    display: none;
  }
`

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { convertCmsDomainToLiveDomain } from "./../../utils/global-util"

function SEO({ meta, lang, yoast, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const {
    yoast_wpseo_title: yoastTitle,
    yoast_wpseo_metadesc: yoastDescription,
    yoast_wpseo_facebook_title: yoastFacebookTitle,
    yoast_wpseo_facebook_description: yoastFacebookDescription,
    yoast_wpseo_facebook_type: yoastFacebookType,
    yoast_wpseo_twitter_title: yoastTwitterTitle,
    yoast_wpseo_twitter_description: yoastTwitterDescription,
    yoast_wpseo_canonical: yoastCanonical,
  } = yoast || {}

  const metaTitle = yoastTitle || title
  const metaDescription = yoastDescription || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: yoastFacebookTitle || metaTitle,
        },
        {
          property: `og:description`,
          content: yoastFacebookDescription || metaDescription,
        },
        {
          property: `og:type`,
          content: yoastFacebookType || `website`,
        },
        {
          property: `og:image`,
          content: `https://digitisable.co.uk/images/og-image.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: yoastTwitterTitle || metaTitle,
        },
        {
          name: `twitter:description`,
          content: yoastTwitterDescription || metaDescription,
        },
      ].concat(meta)}
    >
      {yoastCanonical && (
        <link
          rel="canonical"
          href={convertCmsDomainToLiveDomain(yoastCanonical)}
        />
      )}

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "Digitisable",
          url: "https://digitisable.co.uk/",
          address: {
            "@type": "PostalAddress",
            streetAddress: "The Victoria Building",
            addressLocality: "Newcastle",
            addressRegion: "",
            postalCode: "NE6 5BF",
            addressCountry: "UK",
          },
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
          },
          sameAs: ["https://twitter.com/iwantinspired"],
        })}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  yoast: PropTypes.object,
}

export default SEO

export default {
  initial: {
    opacity: 0,
    x: 50,
  },
  close: {
    opacity: 0,
    x: 50,
    transition: {
      duration: 0.8,
    },
  },
  open: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.8,
      delay: 0.4,
    },
  },
}

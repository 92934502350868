import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"
import vars from "../../styles/vars"
import animationVariants from "../../animations/sub-nav-animations"
import { convertToInternalUrl } from "../../utils/global-util"

const SubNav = ({ data, activeParentSlug, handleNavClick }) => {
  const fallbackIconSrc = ev => {
    ev.target.src = "/icons/fallback-nav-icon.svg"
  }

  return (
    <StyledSubNav
      className="sub-nav"
      variants={animationVariants}
      initial="initial"
    >
      <div className="row">
        {data.map(subItem => {
          const navIcon = `/icons/${subItem.object_slug}.svg`
          return (
            <div
              className="col-12 col-sm-6 col-md-4"
              key={subItem.wordpress_id}
            >
              <Link
                to={activeParentSlug ? convertToInternalUrl(subItem.url) : `/`}
                onClick={e => handleNavClick(e)}
                aria-label={subItem.title}
              >
                <div className="row align-items-center" aria-hidden="true">
                  <div className="col-auto">
                    <img
                      src={navIcon}
                      onError={fallbackIconSrc}
                      style={{ width: 40, height: 40 }}
                      alt={subItem.title}
                      loading="lazy"
                    />
                  </div>
                  <div className="col">
                    <div className="subnav-title">{subItem.title}</div>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </StyledSubNav>
  )
}

export default SubNav

const StyledSubNav = styled(motion.div)`
  a {
    display: block;
    color: ${vars.white};
    text-decoration: none;
    font-weight: normal;
    margin-bottom: 1.4rem;
    font-size: 1.05rem;
    transition: ease-in-out 200ms;

    &:hover {
      color: ${vars.secondaryColour};
    }
  }

  .col-12 {
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid ${vars.darkerGrey};

    &:nth-child(3n + 3) {
      border: none;
    }
  }
`

const easeInBack = [0.6, -0.28, 0.735, 0.045]
const easeOutBack = [0.175, 0.885, 0.32, 1.275]
const easeSmooth = [0.77, 0, 0.175, 1]
const easeOutQuart = [0.17, 0.84, 0.44, 1]

export default {
  easeInBack,
  easeOutBack,
  easeSmooth,
  easeOutQuart,
}

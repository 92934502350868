import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import vars from "../../styles/vars"

const MobileSubNav = ({ navItem, onMobileNavToggle }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleSubNav = (event, hasChildren) => {
    if (hasChildren) {
      event.preventDefault()
      setIsOpen(!isOpen)
    } else {
      onMobileNavToggle()
    }
  }

  return (
    <div className="position-relative">
      <MobileNavParentLink
        open={isOpen}
        key={"mobile-nav-" + navItem.wordpress_id}
        to={`/${navItem.object_slug}`}
        onClick={event =>
          onToggleSubNav(event, navItem.wordpress_children && true)
        }
      >
        {navItem.title}
      </MobileNavParentLink>
      {navItem.wordpress_children && (
        <SubNavContainer open={isOpen}>
          {navItem.wordpress_children.map(subNavItem => (
            <SubNavLink
              key={`mobile-nav-${subNavItem.wordpress_id}`}
              to={`/${navItem.object_slug}/${subNavItem.object_slug}`}
              onClick={onToggleSubNav}
            >
              {subNavItem.title}
            </SubNavLink>
          ))}
        </SubNavContainer>
      )}
    </div>
  )
}

export default MobileSubNav

const MobileNavParentLink = styled(Link)`
  color: ${props => (props.open ? vars.black : vars.white)};
  text-decoration: none;
  display: block;
  position: relative;
  transition: ease-in-out 200ms;
  padding: 0 0 1rem 0;
`

const SubNavContainer = styled.div`
  transition: ease-in-out 200ms;
  height: ${props => (props.open ? "100%" : "0")};
  display: block;
  overflow: hidden;
`

const SubNavLink = styled(Link)`
  color: ${vars.white};
  text-decoration: none;
  margin-bottom: 15px;
  display: block;
  padding-left: 10px;
`

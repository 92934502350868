import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { MenuToggle } from "../common/menu-toggle"
import { motion } from "framer-motion"
import styled from "styled-components"
import vars from "../../styles/vars"
import MobileSubNav from "./mobile-sub-nav"
import {
  NavigationStateContext,
  NavigationDispatchContext,
} from "../../context/navigation-context-provider"
import NavActionEnum from "../../enums/navigation-action-enum"

const MobileNav = () => {
  const data = useStaticQuery(graphql`
    query MobileNavQuery {
      wordpressWpApiMenusMenusItems(slug: { eq: "main-nav" }) {
        slug
        items {
          title
          object_slug
          wordpress_id
          wordpress_children {
            title
            wordpress_id
            object_slug
            description
          }
        }
        wordpress_id
      }
    }
  `)

  const { wordpressWpApiMenusMenusItems: navData } = data

  const navigationState = useContext(NavigationStateContext)
  const navigationDisptach = useContext(NavigationDispatchContext)

  const handleToggleMobileNav = () => {
    navigationDisptach({ type: NavActionEnum.toggleMobileNav })
  }

  return (
    <MobileNavContainer
      initial={false}
      animate={navigationState.isMobileNavOpen ? "open" : "closed"}
    >
      <MenuToggle toggle={handleToggleMobileNav} />
      <MobileNavContent isOpen={navigationState.isMobileNavOpen}>
        <MobileNavScroll>
          <div className="mb-3 position-relative">
            <HomeLink to="/" onClick={handleToggleMobileNav}>
              Home
            </HomeLink>
          </div>
          {navData.items.map(navItem => (
            <MobileSubNav
              key={`mobile-nav-${navItem.wordpress_id}`}
              navItem={navItem}
              onMobileNavToggle={handleToggleMobileNav}
            />
          ))}
        </MobileNavScroll>
      </MobileNavContent>
    </MobileNavContainer>
  )
}

export default MobileNav

const MobileNavContainer = styled(motion.div)`
  position: absolute;
  z-index: 9999;

  @media (min-width: ${vars.screen_xl_min}) {
    display: none;
  }
`

const MobileNavContent = styled.div`
  background: ${vars.primaryColour};
  background: linear-gradient(
    180deg,
    ${vars.primaryColour} 0%,
    ${vars.secondaryColour} 100%
  );
  border-right: 4px solid ${vars.primaryColour};
  width: 310px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: ${props => (props.isOpen ? "0" : "-320px")};
  z-index: 100;
  padding: 80px 0 0 20px;
  transition: ease-in-out 200ms;
`

const MobileNavScroll = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 80px);
`

const HomeLink = styled(Link)`
  color: ${vars.white};
  text-decoration: none;
  display: block;
  position: relative;
`

//// NOTE: Animated mobile nav, come back to this and complete when we have more time.
//// https://codesandbox.io/s/framer-motion-side-menu-mx2rw?fontsize=14&module=%2Fsrc%2FExample.tsx

// const sidebar = {
//   open: (height = 1000) => ({
//     clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
//     transition: {
//       type: "spring",
//       stiffness: 20,
//       restDelta: 2,
//     },
//   }),
//   closed: {
//     clipPath: "circle(30px at 40px 40px)",
//     transition: {
//       delay: 0.5,
//       type: "spring",
//       stiffness: 400,
//       damping: 40,
//     },
//   },
// }

// const MobileNav = () => {
//   const [isOpen, toggleOpen] = useCycle(false, true)
//   const containerRef = useRef(null)

//   return (
//     <MobileNavContainer
//       initial={false}
//       animate={isOpen ? "open" : "closed"}
//       custom={400}
//       ref={containerRef}
//       isOpen={isOpen}
//     >
//       <SideBar variants={sidebar} />
//       <MenuToggle toggle={() => toggleOpen()} />
//     </MobileNavContainer>
//   )
// }

// export default MobileNav

// const MobileNavContainer = styled(motion.div)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: ${props => (props.isOpen ? "300px" : "80px")};
//   height: ${props => (props.isOpen ? "100vh" : "80px")};
//   z-index: 9999;
// `

// const SideBar = styled(motion.div)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   width: 300px;
//   background: #fff;
// `

// const MobileNavToggle = styled(motion.div)`
//   position: absolute;
//   top: 15px;
//   right: 15px;
//   background: ${vars.white};
//   color: ${vars.black};
//   width: 50px;
//   height: 50px;
//   border-radius: 50%;

//   @media (min-width: ${vars.screen_xl_min}) {
//     display: none;
//   }
// `

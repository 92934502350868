import React, { useState, useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import styled from "styled-components"
import vars from "../../styles/vars"
import SubNav from "./sub-nav"
import {
  NavigationStateContext,
  NavigationDispatchContext,
} from "../../context/navigation-context-provider"
import NavActionEnum from "../../enums/navigation-action-enum"
import transitions from "../../animations/transitions"
import { convertToInternalUrl } from "../../utils/global-util"

const Nav = () => {
  const data = useStaticQuery(graphql`
    query MainNavQuery {
      wordpressWpApiMenusMenusItems(slug: { eq: "main-nav" }) {
        slug
        items {
          title
          object_slug
          wordpress_id
          url
          wordpress_children {
            title
            wordpress_id
            object_slug
            description
            url
          }
        }
        wordpress_id
      }
    }
  `)

  const subNavVariants = {
    open: {
      clipPath: "circle(100% at 50% 0px)",
      transition: {
        // type: "spring",
        // stiffness: 50,
        // restDelta: 2,
        duration: 1,
        ease: transitions.easeSmooth,
      },
    },
    close: {
      clipPath: "circle(2% at 50% -80px)",
      transition: {
        // type: "spring",
        // stiffness: 400,
        // damping: 40,
        //when: "afterChildren",
        duration: 1,
        ease: transitions.easeOutQuart,
      },
    },
  }

  const { wordpressWpApiMenusMenusItems: navData } = data

  const [subNavData, setSubNavData] = useState([])

  const navigationDisptach = useContext(NavigationDispatchContext)
  const navigationState = useContext(NavigationStateContext)

  const handleNavClick = e => {
    // Get the parent slug from data attribute, only parents with children will have a data attributre for their slug
    const slug = e.currentTarget.getAttribute("data-slug")
    if (slug) {
      e.preventDefault()
      // Filter nav items by parent slug, pass filtered data to state and set the active parent slug
      const filteredSubNavData = navData.items.filter(
        x => x.object_slug === slug
      )
      setSubNavData(filteredSubNavData[0].wordpress_children)

      if (navigationState.activeParentSlug === slug) {
        // If the parent slug and active slug match this means the nav is already open, close it.
        navigationDisptach({ type: NavActionEnum.close })
      } else {
        // If the parent slug doesnt match the active slug this means the nav is closed, open it.
        navigationDisptach({ type: NavActionEnum.open, parentSlug: slug })
      }
    } else if (navigationState.isNavOpen) {
      // If the slug is null and the nav is open, close it.
      navigationDisptach({ type: NavActionEnum.close })
    }
  }

  return (
    <>
      <div className="container">
        <StyledNav
          className="row no-gutters align-items-center justify-content-center"
          key={navData.wordpress_id}
        >
          <div className="col-auto pr-0 pr-xl-3">
            <Logo to="/" onClick={e => handleNavClick(e)}>
              <img
                alt="Digitisable"
                src={require("../../assets/svg/logos/digitisable-logo.svg")}
                style={{ width: 145, height: 34, margin: "0 0 -5px 0" }}
                loading="lazy"
              />
            </Logo>
          </div>
          {navData.items.map(navItem => (
            <NavItem
              key={navItem.wordpress_id}
              className="col-12 col-xl d-none d-xl-block"
            >
              <NavParentLink
                to={`${convertToInternalUrl(navItem.url)}`}
                onClick={e => handleNavClick(e)}
                data-slug={
                  navItem.wordpress_children ? navItem.object_slug : ""
                }
              >
                {navItem.title}
              </NavParentLink>
            </NavItem>
          ))}
        </StyledNav>
      </div>
      <SubNavContainer
        variants={subNavVariants}
        initial={false}
        animate={navigationState.isNavOpen ? "open" : "close"}
        isOpen={navigationState.isNavOpen}
        className={navigationState.isNavOpen ? "nav-open" : "nav-closed"}
      >
        <div className="container">
          <SubNav
            animate={navigationState.hasSubNavDataChanged ? "open" : "close"}
            data={subNavData}
            activeParentSlug={navigationState.activeParentSlug}
            handleNavClick={handleNavClick}
          />
        </div>
      </SubNavContainer>
    </>
  )
}

export default Nav

const Logo = styled(Link)`
  color: ${vars.white};
  text-decoration: none;
  font-size: 2rem;
  font-weight: 700;
  opacity: 1 !important;
`

const StyledNav = styled.nav`
  height: 80px;

  a {
    transition: ease-out 200ms;
  }

  &:hover {
    a {
      opacity: 0.5;
    }
  }
`

const NavItem = styled.div`
  &:hover {
    > a {
      opacity: 1;
    }
  }
`

const NavParentLink = styled(Link)`
  color: ${vars.white};
  text-decoration: none;
  font-weight: bold;
  display: block;
  text-align: center;

  &:hover {
    opacity: 1;
  }

  @media (max-width: ${vars.screen_lg_max}) {
    padding: 10px 0;
  }
`
const SubNavContainer = styled(motion.div)`
  position: absolute;
  background: ${vars.black};
  width: 100%;
  height: 300px;
  left: 0;
  top: 80px;
  padding-top: 50px;
  border-top: 1px solid ${vars.darkerGrey};

  @media (max-width: ${vars.screen_lg_max}) {
    display: none;
  }
`
